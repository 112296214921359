export const generateInitials = (name: string): string => {
  if (!name) return "";

  // Split name into words and remove numbers & special characters
  const words = name
    .split(/\s+/) // Split by spaces
    .map((word) => word.replace(/[^a-zA-Z]/g, "")) // Remove numbers & special characters
    .filter((word) => word.length > 0); // Remove empty results

  if (words.length === 0) return "";

  // Get first letter of the first word
  const firstLetter = words[0].charAt(0).toUpperCase();

  // Get first letter of the last word if available, otherwise use only first letter
  const lastLetter =
    words.length > 1 ? words[words.length - 1].charAt(0).toUpperCase() : "";

  return firstLetter + lastLetter;
};
