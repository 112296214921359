import { FileTypes } from "@libs/components";

export const isFileAllowed = (file: File | null, allowedTypes: string[]): boolean => {
  if (!file) {
    return false;
  }

  // Extract the MIME type and file extension
  const mimeType = file.type; // e.g., "image/jpeg"
  const fileExtension = file.name.split(".").pop()?.toLowerCase(); // e.g., "jpeg"

  // Check if the MIME type or extension is in the allowed types
  return !!(
    (fileExtension && allowedTypes.includes(fileExtension as FileTypes)) ||
    (mimeType && allowedTypes.some((type) => mimeType.includes(type)))
  );
};

export const isFileSizeValid = (
  file: File | null,
  maxFileSizeInMb: number = 3,
): boolean => {
  if (!file) {
    return false;
  }

  const fileSizeInMb = file.size / (1024 * 1024); // Convert size to MB
  return fileSizeInMb <= maxFileSizeInMb;
};
