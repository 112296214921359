import { humanizeEnum } from "./humanize-enum.util";

type EnumType = Record<string, string | number>;

export const getEnumKeysHumanized = <E extends EnumType>(
  enumObj: E,
): string[] => {
  return Object.keys(enumObj)
    .filter((key) => isNaN(Number(key)))
    .map((key) => humanizeEnum(key));
};

export const getEnumKeyValueHumanized = <E extends EnumType>(
  enumObj: E,
): Record<string, number | string> => {
  return Object.keys(enumObj)
    .filter((key) => isNaN(Number(key)))
    .reduce(
      (acc, key) => {
        acc[humanizeEnum(key)] = enumObj[key as keyof E];
        return acc;
      },
      {} as Record<string, number | string>,
    );
};

export const getEnumKeyValueArrayHumanized = <E extends EnumType>(
  enumObj: E,
  toUpperCase = true,
): { key: string; value: number | string }[] => {
  return Object.keys(enumObj)
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({
      key: toUpperCase ? humanizeEnum(key) : humanizeEnum(key).toLowerCase(),
      value: enumObj[key as keyof E],
    }));
};
